<template>
  <ValidationObserver ref="observer" v-slot="{ valid }">
    <slot :disabled="!open">
      <master-render
        :rows="finalRows"
        v-model="innerValue"
        :disabledAll="!open"
      ></master-render>
    </slot>
    <div class="form-action" v-if="canEdit">
      <template v-if="!open">
        <slot name="edit-button">
          <v-btn class="primary" text @click="enableEditMode">Edit</v-btn>
        </slot>
      </template>
      <template v-else>
        <v-btn text class="primary" @click="clickSubmit" :disabled="!valid"
          >Submit</v-btn
        >
        <v-btn class="ml-4" text @click="cancelChanges()" v-if="!alwaylsOpen"
          >Cancel</v-btn
        >
      </template>
    </div>
  </ValidationObserver>
</template>

<script>
import { flagDisabledAllFields } from "./../../js/custom";
import { getFakeUrl, fakeAbbr } from "../../_helpers/fake-url";

//note usage
//มี 2 option ทั้งสองต้องสร้าง model มาจากข้างนอก
//bind id watching และทำการยกเลิกการแก้ไขถ้ามีการเปลี่ยน id
//binding v-model เข้ามา
//หลังจากใช้เสร็จ call function cancelEditMode()
//option 1: (ไม่ใช้ slot)
//1. ส่ง props rows เข้ามาเพื่อ render fields

//option 2: (ใข้ slot)
//1. render form เข้ามาใน <template v-slot:default="{disabled}"></template>ได้เลย
//2. ใช้ disabled ได้เลย

export default {
  name: "custom-secure-block",
  props: {
    value: Object,
    rows: {
      type: Array,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    alwaylsOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      disabled: true,
    };
  },
  mounted() {},
  watch: {
    id: {
      handler(newVal) {
        //note :fixed bug when add new object to items
        //แทนที่จะเก็บ oldValue ไว้ใน component
        //เอาไปเก็บไว้ที่ object เจ้าของข้อมูลแทน
        //เพราะว่าตอนเพิ่มข้อมูลใน array component ใน indexเก่า ไม่ถูกสร้างใหม่ แต่จะเปลี่ยนข้อมูลเข้ามา render แทน ทำให้ state ต่างๆของ object ก่อนหน้านี้ยังค้างอยู่
        this.cancelChanges();
      },
    },
    disabled: {
      handler(newVal) {},
    },
  },
  methods: {
    clickSubmit() {
      this.$emit("click-submit");
    },
    enableEditMode() {
      this.disabled = false;
      this.oldValue = _.cloneDeep(this.innerValue);
    },
    cancelEditMode() {
      this.disabled = true;
    },
    cancelChanges() {
      if (this.oldValue) {
        this.innerValue = this.oldValue;
        this.oldValue = null;
      }

      this.cancelEditMode();
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    open() {
      if (this.alwaylsOpen) {
        return true;
      }

      return !this.disabled;
    },
    oldValue: {
      get() {
        if (this.innerValue) {
          return this.innerValue.oldValue;
        }
        return null;
      },
      set(val) {
        this.$set(this.innerValue, "oldValue", val);
      },
    },
    finalRows() {
      if (_.isArray(this.rows)) {
        return this.rows;
        // return flagDisabledAllFields({
        //   rows: this.rows,
        //   disabled: this.disabled
        // });
      }
      return [];
    },
  },
};
</script>
