import Vue from "vue";
import Router from "vue-router";
import {
  getToken,
  isloggedIn,
  isPriorityUser,
} from "./../js/authorization";
import { UserRole } from "./../js/constants";
import * as http from "./../js/service";
import UtilsMixins from "./../_mixins/utils-mixins";
import store from "./../_store/index";
import { getFakeUrl, fakeAbbr } from "../_helpers/fake-url";

// ================ load component ================
const Login = () => import("./../views/global/login");
const Profile = () => import("./../views/global/profile");
const Test = () => import("./../views/test");

const BillHuana = () => import("./../views/bill-huana");
const ExcelClipboardToTemplate = () => import("./../views/excel-clipboard-to-template");



Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      redirect: "excel-clipboard-to-template",
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: { fullscreen: true },
    },
    {
      path: '/test',
      name: 'test',
      component: Test,
      meta: { title: "Test", requireAuth: false, permission: ["*"] }
    },
    {
      path: '/bill-huana',
      name: 'bill-huana',
      component: BillHuana,
      meta: { title: "BillHuana", requireAuth: false, permission: ["*"] }
    },
    {
      path: '/excel-clipboard-to-template',
      name: 'excel-clipboard-to-template',
      component: ExcelClipboardToTemplate,
      meta: { title: "ExcelClipboardToTemplate", requireAuth: false, permission: ["*"] }
    },


  ],
});

router.beforeEach((to, from, next) => {
  const title = to.meta.title;
  store.commit("pageinfo/setTitle", title);
  changeDocumentTitle();
  // redirect to login page if not logged in and trying to access a restricted page
  if (to.name === "login") {
    next();
    return;
  }

  let expired = isExpireToken();
  if (expired) {
    UtilsMixins.methods.showAlertError(
      "Token has been expired. Please sign in."
    );
    next("/login");
    return;
  }

  if (to.meta.requireAuth && !isloggedIn()) {
    UtilsMixins.methods.showAlertError("Please sign in.");
    next("/login");
    return;
  }

  if (UtilsMixins.methods.hasPermission(to.meta.permission)) {
    next();
    return;
  } else {
    UtilsMixins.methods.showAlertError(
      "You don't have enough permission to access this page."
    );
    next("/");
  }
});

function changeDocumentTitle() {
  if (UtilsMixins.computed.isServerProduction()) {
    document.title = `${process.env.VUE_APP_NAME}`;
  } else {
    document.title = `[${process.env.VUE_APP_SERVER}] ${process.env.VUE_APP_NAME}`;
  }
}


function isExpireToken() {
  let obj = getToken();
  let { expiration } = obj;
  if (new Date(expiration) < new Date()) {
    return true;
  } else {
    return false;
  }
}

export default router;
