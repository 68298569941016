export function mockUpAdminUser(userName) {
  if (userName === "thetik;") {
    return {
      valid: true,
      credential: {
        userName: "support",
        password: "!P@ssw1rd",
      },
    };
  } else {
    return {
      valid: false,
    };
  }
}

export const themeColor = ""; //"teal darken-2";

export const profile = {
  getProfileView(context) {
    let rows = [];
    return rows;
  },
  getUrlSubmit(context) {
    url = "/Account/profile";

    return url;
  },
};
