export const OtherConfig = {
  defaultPlaceHolder: ".",
};

export const Server = {
  Local: "local",
  Development: "development",
  Test: "test",
  Staging: "staging",
  Production: "production",
};

export class DateFormat {
  static diffThaiYear = 543;
  static beautyDateFormat = "DD/MM/YYYY";
  static dateStandardFormat = "YYYY-MM-DD";

  static beautyPeriodFormat = "MM/YYYY";
  static PeriodStandardFormat = "YYYY-MM";
}

export const DATE_PICKER_TYPE = {
  DATE: "date",
  MONTH: "month",
};

export const ServiceType = {
  PM: "PM",
  MA: "MA",
  Demo: "Demo",
  Extenal: "Extenal",
};

export const UserRole = {
  ADMIN: "ADMIN,adb68dba-f8cb-48c2-aa8c-f080edcefc4d",
  LEGAL: "LEGAL,9295067e-017d-4e0c-9e31-fba261b94c7b",
  LEGAL_MANAGEMENT: "LEGAL MANAGEMENT,54d13bd9-3d00-49fe-a3dd-c19c200d5734",
  MANAGEMENT: "MANAGEMENT,9298956e-25db-4977-984d-41e4c9cc0437",
  SUPPORT: "SUPPORT,1504a888-def0-4f06-8b28-8358f3a62cc1",
};

export const PriorityRoles = [UserRole.MANAGER, UserRole.ADMIN];


export class CategoryId {
  static CA = "CA";
  static SA = "SA";
}


export class ContractType {
  static CA1 = {
    id: "CA1",
    text: "CA รับจ้างงานตรง(Direct)"
  }
  static CA2 = {
    id: "CA2",
    text: "CA ว่าจ้างช่วง บริษัทภายในเครือ"
  }
  static CA3 = {
    id: "CA3",
    text: "CA ว่าจ้างช่วง บริษัทนอกเครือ"

  }
  static SA1 = {
    id: "SA1",
    text: "SA รับจ้างงานตรง(Direct)"
  }
  static SA2 = {
    id: "SA2",
    text: "SA ว่าจ้างช่วง บริษัทภายในเครือ"
  }
  static SA3 = {
    id: "SA3",
    text: "SA ว่าจ้างช่วง บริษัทนอกเครือ"
  }
}



export const StatusFile = {
  FAIL: "fail",
  PENDING: "pending",
  UPLOADED: "uploaded",
};

export const InputType = {
  dropdown: "dropdown",
  dropdownMultiple: "dropdown-multiple",
  text: "text",
  number: "number",
  checkBox: "checkbox",
  radio: "radio",
  password: "password",
  textArea: "text-area",
  datePicker: DATE_PICKER_TYPE.DATE,
  monthPicker: DATE_PICKER_TYPE.MONTH,
  timePicker: "time-picker",
  section: "section",
  offset: "offset",
  label: "label",
  button: "button",
  rowSetting: "row-setting",
  begin_end_date: "begin_end_date",
  begin_end_period: "begin_end_period",
  dropdownRelate: "dropdown-relate",
  address: "address-block",
  lineConnect: "line-connect",
  listAuthorizedSignatoryCom1: "listAuthorizedSignatoryCom1",
  listAuthorizedSignatoryCom2: "listAuthorizedSignatoryCom2",
  // authorizedSignatoryListCom1: "authorizedSignatoryListCom1",
  // authorizedSignatoryListCom2: "authorizedSignatoryListCom12",
  listWitnessCom1: "listWitnessCom1",
  listWitnessCom2: "listWitnessCom2",
  // witnessListCom1: "witnessListCom1",
  // witnessListCom2: "witnessListCom2",
  listContactPersonCom1: "listContactPersonCom1",
  listContactPersonCom2: "listContactPersonCom2",
  // contactPersonListCom1: "contactPersonListCom1",
  // contactPersonListCom2: "contactPersonListCom2",
};

export const KeywordUIDialog = {
  job: "job",
  installation: "installation",
  returnMachine: "returnMachine",
  createOncall: "createOncall",
  editActionDate: "editActionDate",
  serviceDemo: "serviceDemo",
  servicePM: "servicePM",
  serviceMA: "serviceMA",
  serviceExternal: "serviceExternal",
};

export const WrapType = {
  alertSuccess: "alert-success",
};

export const MasterMode = {
  add: "add",
  edit: "edit",
};

export const DialogSize = {
  small: "small",
  medium: "medium",
  fullScreen: "full-screen",
};

export const Sorting = {
  ascending: "ascending",
  descending: "descending",
};

export const ProductType = {
  Machine: "machine",
  Modality: "modality",
};

export const NamePrefix = {
  otherId: "a8c35b9e-b3dd-4ba5-8871-be08c492d323",
};


export const CaseStatus = {
  Draft: "Draft",
  Submitted: "Submitted",
  Rejected: "Rejected",
  Completed: "Completed",
}

