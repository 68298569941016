<template>
  <div>

  </div>
</template>

<script>
import { InputType } from "./../js/constants";

export const extendInputList = [];

export default {
  props: {
    value: [String, Object, Array, Boolean, Number],
    model: Object,
    type: String,
    disabled: Boolean,
  },
  components: {},
  data() {
    return {
      inputType: InputType,
    };
  },
};
</script>
