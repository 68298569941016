<template>
  <div>
    <v-btn
      @click="saveDivAsImage()"
      class="red--text"
      text
    >ดาวน์โหลด</v-btn>

    <div
      ref="captureDiv"
      style="width: -moz-fit-content;width: fit-content;"
    >
      <slot></slot>
    </div>

  </div>
</template>


<script>
import html2canvas from "html2canvas";

export default {
  name: "save-content-image",
  methods: {
    async saveDivAsImage(divId) {
      // Get the div element
      const div = this.$refs.captureDiv;

      // Convert the div element to a canvas element
      const canvas = await html2canvas(div);

      // Get the data URL of the canvas
      const dataURL = canvas.toDataURL("image/png");

      // Create an anchor element
      const link = document.createElement("a");
      link.download = "image.png";
      link.href = dataURL;

      // Trigger a click on the anchor to initiate the download
      link.click();
    },
  },
};
</script>