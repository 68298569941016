import * as http from './../js/service';


export default {
  namespaced: true,
  state: {
    sueId: null,
    agreementsModel: [],
    auctions: [],
    model: {
      assetInvestigate: {
        investigateResult: null,
        workingCompany: null,
        price: null,
        ownership: null,
      },
      assetFreeze: {
        freezeType: null,
        assetStatus: null,
        assetDescription: null,
        price: null,
      },
      customer: {},
      blockCode: null,
    },
  },

  actions: {
    getAuctions({ state, commit, dispatch }, sueId) {
      let url = `/sue/${sueId}/auctions`;

      return http.get(url).then(res => {
        state.auctions = res.items;
        return res
      })
    },
  },
};
