<template>
  <div>
    <!-- search -->
    <master-search
      ref="search"
      @results-search="setItems"
      :url="config.url.search"
      :rows="config.search.rows"
      :showSearch="showSearch"
      :searchOnInit="config.search.searchOnInit"
      @clicked-search="$emit('clicked-search', $event)"
    ></master-search>

    <!-- form -->
    <master-form
      ref="maintainForm"
      :shortName="config.shortName"
      :form="config.form"
      :url="config.url"
      :permission="config.permission"
      @result-submit="submitted"
      @close-dialog="dialogClosed"
      @get-data-success="attachPermissionToModel"
    ></master-form>

    <slot name="master-component-center"></slot>

    <!-- table -->
    <master-table
      class="mt-7"
      ref="masterTable"
      @click-add="clickAdd"
      @click-edit="clickEdit"
      @click-remove="removeData"
      :columns="config.table.columns"
      :buttonAddText="`Add ${config.shortName}`"
      :items="items"
      :showAdd="showAdd"
      :showEdit="showEdit"
      :showRemove="showRemove"
      :showSelect="showSelect"
      :title="config.table.title"
      :permission="config.permission"
      :bulkAction="config.table.bulkAction"
      :showColumnAction="config.table.showColumnAction"
      :uniqueKey="config.table.uniqueKey"
    >
      <template
        v-for="slotName in renderScopdSlot"
        v-slot:[slotName]="obj"
      >
        <slot
          :name="slotName"
          v-bind="obj"
        ></slot>
      </template>
    </master-table>
  </div>
</template>

<script>
import masterSearch from "./master-search";
import masterForm from "./master-form";
import masterTable from "./master-table";
import { fakeAbbr } from "../../_helpers/fake-url";

export default {
  name: "master-component",
  props: {
    config: {
      type: Object,
      required: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    showAdd: {
      type: Boolean,
      default: true,
    },
    showEdit: {
      type: Boolean,
      default: true,
    },
    showRemove: {
      type: Boolean,
      default: true,
    },
    searchAfterSubmit: {
      type: Boolean,
      default: false,
    },
    paramsId: {
      type: String,
      default: "id",
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    "master-search": masterSearch,
    "master-form": masterForm,
    "master-table": masterTable,
  },
  data() {
    return {
      items: [],
      id: null,
    };
  },
  watch: {
    id: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.showFormDialog();
        }
      },
    },
  },
  created() {
    //set id if mode edit
    if (this.paramsId) {
      let id = this.$route.params[this.paramsId] || null;
      this.setId(id);
    }
  },
  methods: {
    setItems(items) {
      this.items = items;
    },
    setId(id) {
      this.id = id;
    },
    setUrl() {
      if (!this.paramsId) {
        return false;
      }

      let routeId = this.$route.params.id;
      let condition1 = routeId && !this.id;
      let condition2 = !routeId && this.id;

      if (condition1 || condition2) {
        this.$router.push({
          name: this.$route.name,
          params: { id: this.id },
        });
      }
    },
    clickAdd() {
      this.setId(null);
      this.showFormDialog();
    },
    clickEdit(item, index) {
      //overrided by model
      if (item.hasOwnProperty("form")) {
        this.config.form = item.form;
      }
      this.$emit("click-edit", item, index);
      this.setId(item.id);
      this.setUrl();
    },
    showFormDialog(item, index) {
      setTimeout(() => {
        this.$refs.maintainForm.showDialog(this.id);
      }, 100);
    },
    hideFormDialog(item, index) {
      this.$refs.maintainForm.hideDialog();
      this.dialogClosed();
    },
    dialogClosed() {
      this.setId(null);
      this.setUrl();
    },
    submitted(res) {
      this.hideFormDialog();
      this.showAlertSuccess();
      this.replaceModel(res.simple); //listing model
      this.$emit("submitted", res);
    },
    search() {
      this.$refs.search.search();
    },
    replaceModel(newModel) {
      if (this.searchAfterSubmit) {
        this.search();
        return;
      }

      let index = this.items.findIndex((x) => x.id === newModel.id);

      if (index >= 0) {
        this.items.splice(index, 1, newModel);
      } else {
        this.items.splice(0, 0, newModel);
      }
    },
    removeData(item, index) {
      if (confirm("คุณแน่ใจหรือไม่ที่จะลบข้อมูลนี้")) {
        let url = this.config.url.remove;

        this.$http.delete(`${url}/${item.id}`).then((res) => {
          this.items.splice(index, 1);
          this.showAlertSuccess();
        });
      }
    },
    getSelectedItems() {
      return this.$refs.masterTable.getSelectedItems();
    },
    getItems() {
      return this.$refs.masterTable.getItems();
    },
    attachPermissionToModel(model) {
      let { permission: permissionConfig } = this.config;
      let attachModel = {
        canAdd: this.hasPermission(permissionConfig.add),
        canEdit: this.hasPermission(permissionConfig.edit),
        canRemove: this.hasPermission(permissionConfig.remove),
      };

      this.$set(model, "permission", attachModel);
    },
  },
  computed: {
    renderScopdSlot() {
      let keys = Object.keys(this.$scopedSlots);
      let items = [];
      items = keys.filter((x) => x.includes("table"));

      return items;
    },
  },
};
</script>
