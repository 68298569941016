<template>
  <div class="master-table">
    <v-row>
      <v-col>
        <v-row class="nav-table">
          <v-col>
            <h2 class="title">
              {{ title }} ({{ items.length | currency(0) }})
            </h2>
          </v-col>
          <v-col class="text-right">
            <slot name="table-other-button"> </slot>

            <slot
              v-if="conditionShowAdd"
              name="table-button-add"
            >
              <button-add
                icon="mdi-plus"
                :text="buttonAddText"
                @clicked="addData()"
                v-if="conditionShowAdd"
              ></button-add>
            </slot>
          </v-col>
        </v-row>

        <!-- bulk action -->
        <v-row v-if="showSelect">
          <v-col>
            <div class="input-group">
              <v-select
                :items="bulkActionFilter"
                v-model="action"
                label="Action"
                outlined
                return-object
                dense
              ></v-select>
              <v-btn
                :disabled="!showButtonSubmitBulkAction"
                @click="action.func()"
                class="primary"
                text
              >Apply</v-btn>
            </div>
          </v-col>
        </v-row>
        <!-- end bulk action -->

        <v-simple-table dense>
          <thead>
            <tr>
              <th
                width="10"
                class="text-center"
                v-if="showSelect"
              >
                <v-checkbox
                  v-model="isSelectAll"
                  @change="selectAll"
                  :indeterminate="indeterminate"
                ></v-checkbox>
              </th>
              <th
                class="text-center"
                width="10"
              >No.</th>
              <th
                v-for="(column, index) in columnOrdered"
                :key="index"
              >
                {{ column.text }}
              </th>
              <th
                class="col-action"
                v-show="showColumnAction"
              ></th>
            </tr>
          </thead>
          <tbody>
            <!-- todo remove forward -->
            <!-- <tr
              class="yellow"
              v-if="!isProductionEnvironment"
            >
              <td v-if="showSelect"></td>
              <td></td>
              <td
                v-for="(column, index) in columnOrdered"
                :key="index"
              >
                {{ column.name }}
              </td>
              <td v-show="showColumnAction"></td>
            </tr> -->
            <!-- end todo remove forward -->

            <tr
              v-for="(item, index) in pagingItems"
              :key="item[uniqueKey]"
              :class="[item.class, item.classFunc ? item.classFunc(item) : '']"
            >
              <td v-if="showSelect">
                <v-checkbox
                  v-model="item.isSelect"
                  dense
                ></v-checkbox>
              </td>
              <td class="text-center">{{ item.no }}</td>
              <td
                v-for="(column, index) in columnOrdered"
                :key="index"
                :class="[
                  column.class,
                  column.classFunc ? column.classFunc(item) : '',
                ]"
                :style="column.style"
              >
                <slot
                  :name="`table-${column.name}`"
                  v-bind="{ item, index }"
                >
                  <span v-if="(column.hasOwnProperty('numeric'))">
                    {{ getText(item, column.name) | currency(column.numeric) }}
                  </span>
                  <span v-else>{{ getText(item, column.name) }}</span>
                </slot>
              </td>
              <td
                class="col-action"
                v-show="showColumnAction"
              >
                <slot
                  name="table-custom-event-button"
                  :item="item"
                  :index="index"
                ></slot>
                <custom-tooltip
                  v-slot="{ on }"
                  title="Details"
                >
                  <v-icon
                    v-show="showEdit"
                    v-on="on"
                    color="info"
                    @click="clickEdit(item, index)"
                  >mdi-pencil</v-icon>
                </custom-tooltip>

                <custom-tooltip
                  v-slot="{ on }"
                  title="Remove"
                  v-if="showRemove && canRemove"
                >
                  <v-icon
                    v-on="on"
                    color="error"
                    class="ml-4"
                    @click="clickRemove(item, index)"
                  >mdi-delete</v-icon>
                </custom-tooltip>
              </td>
            </tr>
            <tr
              v-show="pagingItems.length === 0"
              class="border-no-result"
            >
              <td
                colspan="100%"
                class="text-center"
              >No Result</td>
            </tr>
          </tbody>
        </v-simple-table>
        <div class="text-center mt-5">
          <pagination
            :items="items"
            @results="setPagingItems"
          ></pagination>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import pagination from "./../../components/custom/pagination";
import { InputType } from "../../js/constants";

export default {
  props: {
    buttonAddText: {
      type: String,
    },
    columns: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    permission: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showRemove: {
      type: Boolean,
      default: true,
    },
    showEdit: {
      type: Boolean,
      default: true,
    },
    showAdd: {
      type: Boolean,
      default: true,
    },
    showColumnAction: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "Results",
    },
    uniqueKey: {
      type: String,
      default: "id",
    },
    bulkAction: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    pagination: pagination,
  },
  data() {
    return {
      action: null,
      isSelectAll: false,
      pagingItems: [],
    };
  },
  methods: {
    setPagingItems(results) {
      this.pagingItems = results;
    },
    getText(item, key) {
      return _.get(item, key);
    },
    addData() {
      this.$emit("click-add");
    },
    clickEdit(item, index) {
      this.$emit("click-edit", item, index);
    },
    clickRemove(item, index) {
      this.$emit("click-remove", item, index);
    },
    selectAll(val) {
      this.pagingItems.forEach((item) => {
        item.isSelect = val;
      });
    },
    getSelectedItems() {
      return this.items.filter((x) => x.isSelect === true);
    },
    getItems() {
      return this.items;
    },
  },
  computed: {
    conditionShowAdd() {
      return this.hasPermission(this.permission.add) && this.showAdd;
    },
    bulkActionAuthorize() {
      return this.bulkAction.filter((action) => {
        let result = this.hasPermission(action.permission);
        return result;
      });
    },
    bulkActionFilter() {
      return this.bulkActionAuthorize.filter((action) => {
        let condition1 = true;

        if (action.condition) {
          condition1 = action.condition({
            items: this.items,
            selected: this.getSelectedItems(),
          });
        }

        return condition1;
      });
    },
    showSelect() {
      return this.bulkActionAuthorize.length > 0;
    },
    showButtonSubmitBulkAction() {
      var condition1 = this.action ? true : false;
      var condition2 = this.action?.allowEmpty
        ? true
        : this.items.some((x) => x.isSelect);

      return condition1 && condition2;
    },
    canRemove() {
      let result = this.hasPermission(this.permission.remove);
      return result;
    },
    columnOrdered() {
      return this.columns;
    },
    fieldConfig() {
      return {
        isSelect: {
          name: "isSelect",
          type: InputType.checkBox,
        },
      };
    },
    indeterminate() {
      this.pagingItems.forEach((item) => {
        if (!item.hasOwnProperty("isSelect")) {
          this.$set(item, "isSelect", false);
        }
      });
      let itemGb = _.groupBy(this.pagingItems, "isSelect");

      let keys = Object.keys(itemGb);

      let result = keys.length > 1;
      return result;
    },
  },
};
</script>

<style>
/*.master-table .v-input.v-input--checkbox .v-input__slot,
.master-table .v-input.v-input--radio-group .v-input__slot .v-radio {
  background-color: transparent !important;
  border-color: transparent !important;
}*/
</style>

<style scoped>
/*.v-input--checkbox {
  margin: 0px;
  padding: 0px;
}

.v-input--checkbox >>> .v-input__slot {
  margin-bottom: 0px;
}

.v-input--checkbox >>> .v-messages {
  display: none;
}*/
</style>
