import Vue from "vue";
import App from "./App";
import router from "./router/index";
import store from "./_store/index";
import vuetify from "./_plugins/vuetify";
// import './registerServiceWorker'
import "./components";

import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe, {
  // options
});

import number from "@coders-tm/vue-number-format"
// register directive v-number and component <number>
Vue.use(number, { precision: 2 })



// import './_plugins'
import "./filters";
import "./directives";
import "./_plugins/vee-validate-config";
import * as http from "./js/service";

import UtilsMixins from "./_mixins/utils-mixins";

import "./assets/style/_theme.scss";

Vue.mixin({ ...UtilsMixins }); //global mixins
Vue.prototype.$http = http;

String.prototype.equals = function (str) {
  return (
    str != null &&
    typeof str === "string" &&
    this.toLowerCase() === str.toLowerCase()
  );
};

Vue.config.productionTip = false;

// https://github.com/vuetifyjs/vuetify/issues/9999#issuecomment-569932219
const ignoreWarnMessage =
  "The .native modifier for v-on is only valid on components but it was used on <div>.";
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
};

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
